<template>
  <div>
    <!-- First block -->
    <v-container class="first-block pa-0" id="home" fluid>
      <v-img :src="images.map" class="block-bg">
        <v-row align="center" justify="center" class="screen-block">
          <v-col class="text-center">
            <v-row justify="center" align="center">
              <v-col cols="12" lg="10" xl="6">
                <v-card>
                  <v-row no-gutters align="center" justify="center">
                    <v-col cols="6">
                      <v-row>
                        <v-col>
                          <v-img :src="images.logo" width="60%" class="mx-auto"/>
                          <v-card-text class="grey--text px-15 mt-5" style="font-size: 2rem; line-height: 2.5rem; font-weight: 100">
                            Shipping to over 240 countries and territories with Fedex/TNT, DHL, UPS.
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col></v-col>
                      </v-row>
                    </v-col>
                    <v-col>
                      <GetQuoteForm />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-img>
    </v-container>
    <!-- End first block -->

    <!-- Who we are -->
    <v-parallax :src="images.background" class="screen-block" id="who-we-are">
      <v-container class="screen-block fill-height">
        <v-row justify="space-around" align="center">
          <v-col cols="10" md="6">
            <v-card class="pa-16" data-aos="fade-right" data-aos-duration="700" data-aos-easing="ease-in-out">
              <v-card-text class="py-16 text-center">
                <p class="who-we-are">
                  Shipping to over 240 countries and territories; our expertise has been gained through personal service
                  to our customers. We know you need a service you can depend on, so we only use “household name” international
                  couriers. When your envelope/parcel/pallet is collected it is the courier’s own vehicle that collects, and
                  delivers.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="10" md="6" lg="4">
            <v-card class="my-5" data-aos="fade-left" data-aos-offset="100" data-aos-easing="ease-in-out">
              <v-row justify="space-between" no-gutters>
                <v-col cols="auto" class="pa-0">
                  <v-responsive min-height="100%" width="150" class="blue d-flex align-center text-center number-label">
                    01
                  </v-responsive>
                </v-col>
                <v-col>
                  <v-card-title>We invoice our</v-card-title>
                  <v-card-subtitle>customers directly</v-card-subtitle>
                  <v-card-text>
                    We invoice our customers directly for the services of DHL, FedEx, TNT and UPS
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="my-5" data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-out">
               <v-container class="pa-0">
                <v-row justify="space-between" no-gutters>
                  <v-col cols="auto" class="pa-0">
                    <v-responsive min-height="100%" width="150" class="blue d-flex align-center text-center number-label">
                      02
                    </v-responsive>
                  </v-col>
                  <v-col>
                    <v-card-title>Our customers</v-card-title>
                    <v-card-subtitle>receive our discounts</v-card-subtitle>
                    <v-card-text>
                      We work daily with DHL, FedEx, TNT and UPS which means we get large discounted rates that we pass on, saving our customers money
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-card class="my-5" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-out">
               <v-container class="pa-0">
                <v-row justify="space-between" no-gutters>
                  <v-col cols="auto" class="pa-0">
                    <v-responsive min-height="100%" width="150" class="blue d-flex align-center text-center number-label">
                      03
                    </v-responsive>
                  </v-col>
                  <v-col>
                    <v-card-title>We provide track</v-card-title>
                    <v-card-subtitle>and trace on all shipments</v-card-subtitle>
                    <v-card-text>
                      We select the best pick up/delivery courier for your business needs. Our premier customer service includes the track 'n trace of all shipments
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
    <!-- End who we are -->

    <!-- Tracking block -->
    <v-container fluid class="blue-grey darken-4 white--text text-center" id="parcel-tracking">
      <v-row justify="center" align="center" class="mb-16">
        <v-col cols="4" class="tracking-block">
          <h2 class="text-uppercase">Parcel tracking</h2>
          <p class="grey--text">Every consignment shipped with us has a unique tracking number which can be tracked 24/7</p>
          <v-card class="pa-16 blue" data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-out">
            <v-card-text>
              <v-text-field placeholder="Tracking number" solo height="70" class="tracking-number-input"/>
              <v-btn color="secondary" large class="mt-8">Track parcel</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- End tracking block -->

    <!-- Contacts form block -->
    <v-container class="screen-block contacts-block fill-height" fluid id="contact-us">
      <v-row justify="center" align="center">
        <v-col cols="10" md="6">
          <v-card>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-row align="center" justify="center">
                  <v-col class="py-16">
                    <v-card-text class="text-center">
                      <h2 class="text-center mb-5">Our contacts</h2>
                      <div>Europa House</div>
                      <div>Barcroft Street</div>
                      <div>Bury</div>
                      <div>Lancs</div>
                      <div>BL9 5BT</div>
                      <h3 class="mt-5">08444 930 694</h3>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical/>
              <v-col>
                <v-form class="ma-10">
                  <h2 class="text-center mb-5">Write us</h2>
                  <v-text-field outlined label="Your name" dense/>
                  <v-text-field outlined label="Phone or email" dense/>
                  <v-textarea outlined label="Message" dense/>
                  <div class="text-center">
                    <v-btn color="primary">Send</v-btn>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- End contacts form block -->

    <!-- Footer -->
    <v-container fluid class="pa-0">
      <v-footer padless dark>
        <v-card
            flat
            tile
            width="100%"
            class="text-center py-10"
        >
          <v-card-text>
            <v-btn
                class="mx-4"
                icon
            >
              <v-icon size="24px">mdi-facebook</v-icon>
            </v-btn>
            <v-btn
                class="mx-4"
                icon
            >
              <v-icon size="24px">mdi-twitter</v-icon>
            </v-btn>
            <v-btn
                class="mx-4"
                icon
            >
              <v-icon size="24px">mdi-linkedin</v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="my-0 py-0">
            <a href="#" class="grey--text">Privacy Policy</a> | <a href="#" class="grey--text">Terms & Conditions</a>
          </v-card-text>

          <v-card-text>
            Copyright ©D2D {{ new Date().getFullYear() }}
          </v-card-text>
        </v-card>
      </v-footer>
    </v-container>
    <!-- End footer -->
  </div>
</template>

<style scoped>
  .number-label {
    color: white;
    font-size: 4em;
    font-weight: bold;
  }

  .screen-block {
    min-height: calc(100vh - 64px) !important;
  }

  .first-block .block-bg > .v-image__image {
    filter: blur(3px) grayscale(100%);
  }

  .first-block blockquote {
    font-size: 1.5rem;
    line-height: 2em;
    font-weight: 300;
  }

  .step-number {
    font-size: 3em;
    font-weight: bold;
    line-height: 1em;
  }

  .who-we-are {
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.5rem;
  }

  .v-label--active {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -2px;
    background: #fff;
    border-radius: 3px;
  }

  .tracking-block {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tracking-number-input input {
    font-size: 1.6em;
  }

  .contacts-block {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../assets/background-images/img3-min.jpg");
    background-size: cover;
    background-position: center;
  }
  #who-we-are {
    background: #111;
  }
</style>

<script>
import 'aos/dist/aos.css';
import MapImg from '@/assets/background-images/map3-min.jpg'
import LogoImg from '@/assets/logo2.jpg'
import BackgroundImg from '@/assets/background-images/img1-min.jpg'
import GetQuoteForm from './components/GetQuoteForm'

export default {
  name: 'HomePage',
  components: {
    GetQuoteForm
  },
  data() {
    return {
      images: {
        map: MapImg,
        logo: LogoImg,
        background: BackgroundImg
      }
    }
  },

  mounted() {
    import('aos').then(AOS => AOS.init());
  }
}
</script>