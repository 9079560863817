<template>
  <v-card class="rounded-l-0 elevation-0 blue">
    <v-toolbar
        color="primary"
        dark
        flat
    >
      <v-toolbar-title>Get a free instant quote</v-toolbar-title>
    </v-toolbar>
    <v-form class="white--text">
      <v-card-text>
        <v-row align="center">
          <v-col cols="auto">
            <div class="step-number">
              1
            </div>
          </v-col>
          <v-col>
            <label> Collection country
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-autocomplete
                    outlined
                    dense
                    chips
                    small-chips
                    label="Country from"
                    multiple
                    background-color="white"
                ></v-autocomplete>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </label>
          </v-col>
          <v-col>
            <label> Collection postcode
              <v-autocomplete
                  outlined
                  dense
                  chips
                  small-chips
                  label="Suburb or postcode"
                  multiple
                  background-color="white"
              ></v-autocomplete>
            </label>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-card-text>
        <v-row align="center">
          <v-col cols="auto">
            <div class="step-number">
              2
            </div>
          </v-col>
          <v-col>
            <label> Destination country
              <v-autocomplete
                  outlined
                  dense
                  chips
                  small-chips
                  label="Country to"
                  multiple
                  background-color="white"
              ></v-autocomplete>
            </label>
          </v-col>
          <v-col>
            <label> Destination postcode
              <v-autocomplete
                  outlined
                  dense
                  chips
                  small-chips
                  label="Suburb or postcode"
                  multiple
                  background-color="white"
              ></v-autocomplete>
            </label>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-card-text>
        <v-row align="center" justify="space-between">
          <v-col cols="auto">
            <div class="step-number pt-2">
              3
            </div>
          </v-col>
          <v-col>
            <v-row no-gutters>
              <v-col>
                <v-card-title class="py-0">
                  Package information
                </v-card-title>
              </v-col>
            </v-row>
            <v-row align="baseline" v-for="(item, index) in packages" :key="index">
              <v-col>
                <v-chip label>kg/cm</v-chip>
              </v-col>
              <v-col>
                <v-text-field
                    outlined
                    label="Length"
                    background-color="white"
                    dense
                    v-model="item.length"
                />
              </v-col>
              <v-col>
                <v-text-field
                    outlined
                    label="Width"
                    background-color="white"
                    dense
                    v-model="item.width"
                />
              </v-col>
              <v-col>
                <v-text-field
                    outlined
                    label="Height"
                    background-color="white"
                    dense
                    v-model="item.height"
                />
              </v-col>
              <v-col>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-text-field
                      outlined
                      label="Weight"
                      background-color="white"
                      dense
                      v-model="item.weight"
                  />
                  <span>{{errors}}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="auto">
                <v-btn
                    icon
                    @click="removePackage(index)"
                    :style="{visibility: index > 0 ? 'visible' : 'hidden'}"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-card-text>
        <v-row align="center">
          <v-col cols="auto">
            <div class="step-number">
              4
            </div>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-btn color="primary" @click="addPackage"> Add another package</v-btn>
              </v-col>
              <v-col style="font-size: 2em">
                or
              </v-col>
              <v-col>
                <v-btn color="secondary">Get Quote</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'GetQuoteForm',
  components: {
    ValidationProvider
  },
  data() {
    return {
      packages: [
        {}
      ]
    }
  },
  methods: {
    addPackage() {
      this.packages.push({});
    },
    removePackage(index) {
      if (this.packages.length > 1) {
        this.packages.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>
  .invisible {
    visibility: hidden;
  }
</style>